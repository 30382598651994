import React from "react"
import { Link } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import HeroHeader from "../components/HeroHeader"

const NotFoundPage = () => (
  <Layout>
    <SEO title="Página não encontrada" />
    <HeroHeader
      heroMode={"is-dark"}
      heroClass={"is-triangulo"}
      heroSize={"is-small"}
      navActive={"ajuda"}
    ></HeroHeader>
    <section className="container m-t-lg m-b-lg">
      <div className="block box has-text-centered">
        <h1 className="title has-text-danger text-404">404</h1>
        <h2 className="subtitle has-text-danger is-uppercase">
          Página não encontrada
        </h2>
        <Link className="m-t-lg m-b-lg" to="/">
          Voltar a página inicial
        </Link>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
